.container {
	position: relative;
	background-color: #c2d1d6;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-height: 100%;
	overflow-x: hidden;
}

.header {
	flex: 0 0 50px;
	max-height: 50px;
}

.topNav {
	padding: 10px 20px 10px 20px;
	height: 100%;
	overflow: hidden;
}

.topNav a {
	font-family: "Imbue";
	height: 100%;
	display: flex;
	align-items: center;
	float: left;
	padding: 0 5px;
	color: #102127;
	opacity: 60%;
	text-align: center;
	text-decoration: none;
	font-size: 16px;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.topNav a:hover {
	color: #213b44;
	opacity: 80%;
}

.topNav a.active {
	color: #213b44;
}

.topNavRight {
	height: 100%;
	display: flex;
	align-items: center;
	float: right;
}

.mainContent {
	flex: 1 1 auto;
}

.sunkenCityStory {
	display: block;
	height: 100px;
	width: 100px;
	position: absolute;
	top: 50%;
	right: -45px;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
}

@-webkit-keyframes creatureMovement {
	0% { transform: rotate(-16deg); }
	50% { transform: rotate(-4deg); }
	100% { transform: rotate(-16deg); }
}

@keyframes creatureMovement {
	0% { transform: rotate(-16deg); }
	50% { transform: rotate(-4deg); }
	100% { transform: rotate(-16deg); }
}

.sunkenCityStoryImage {
	height: 100px;
	transform: rotate(-24deg);
	animation-name: creatureMovement;
	-webkit-animation: creatureMovement;
	animation-duration: 8s;
	-webkit-animation-duration: 8s;
	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

.sunkenCityQuote {
	font-family: "Special Elite";
	color: #102127;
	opacity: 80%;
	line-height: 14px;
	position: absolute;
	top: 63%;
	left: 60%;
	font-size: 10px;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.footer {
	flex: 0 0 60px;
	max-height: 60px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.footer h1 {
	color: #102127;
	font-family: "Imbue";
	font-size: 12px;
	opacity: 60%;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.footer h2 {
	color: #102127;
	font-family: "Shippori Antique B1";
	font-size: 10px;
	opacity: 60%;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}

@media only screen and (max-width: 800px) {
	.sunkenCityQuote {
		left: 45%;
		font-size: 12px;
	}
}

@media only screen and (max-width: 450px) {
	.sunkenCityQuote {
		left: 30%;
		font-size: 9px;

	}
}
