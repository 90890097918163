.container {
	background-color: #102127;
	min-height: 100vh;
	height: 100%;
	padding: 20px 30px;
}

.storyContainer {
	font-family: "Special Elite";
	margin-top: 20px;
	color: #c2d1d6;
	display: block;
	max-width: 75vw;
}

.storyContainer p {
	font-size: 2vh;
	max-width: 55%;
	margin-bottom: 20px;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}

.track1 {
	min-width: 45vw;
}

.track2 {
	margin-left: 9vw;
	min-width: 45vw;
}

.track3 {
	margin-left: 18vw;
	min-width: 45vw;
}

.albumTitleContainer {
	width: 100%;
}

.albumTitle {
	text-align: right;
	color: #c2d1d6;
	font-family: "Shippori Antique B1";
	font-size: 4vh;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-o-user-select: none;
	user-select: none;
}

@-webkit-keyframes algaMovement {
	0%, 100% { transform: rotate(-12deg) scaleX(-1); }
	50% { transform: rotate(-20deg) scaleX(-1); }
}

@keyframes algaMovement {
	0% { transform: rotate(-12deg) scaleX(-1); }
	50% { transform: rotate(-20deg) scaleX(-1); }
	100% { transform: rotate(-12deg) scaleX(-1); }
}

.alga {
	position: absolute;
	bottom: 30px;
	right: 220px;
	opacity: 30%;
	height: 200px;
	animation-name: algaMovement;
	-webkit-animation: algaMovement;
	animation-duration: 12s;
	-webkit-animation-duration: 12s;
	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

.pillar {
	position: absolute;
	bottom: 0;
	right: 100px;
	opacity: 100%;
	height: 100%;
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}

@-webkit-keyframes bounce1 {
	0%, 50%, 100% {
		-webkit-transform: translateY(0) scaleX(-1);
		transform: translateY(0) scaleX(-1);
	}
	25% {
		-webkit-transform: translateY(-5px) scaleX(-1);
		transform: translateY(-5px) scaleX(-1);
	}
	75% {
		-webkit-transform: translateY(-3px) scaleX(-1);
		transform: translateY(-3px) scaleX(-1);
	}
}

@keyframes bounce1 {
	0%, 50%, 100% {
		-moz-transform: translateY(0) scaleX(-1);
		-ms-transform: translateY(0) scaleX(-1);
		-webkit-transform: translateY(0) scaleX(-1);
		transform: translateY(0) scaleX(-1);
	}
	25% {
		-moz-transform: translateY(-5px) scaleX(-1);
		-ms-transform: translateY(-5px) scaleX(-1);
		-webkit-transform: translateY(-5px) scaleX(-1);
		transform: translateY(-5px) scaleX(-1);
	}
	75% {
		-moz-transform: translateY(-3px) scaleX(-1);
		-ms-transform: translateY(-3px) scaleX(-1);
		-webkit-transform: translateY(-3px) scaleX(-1);
		transform: translateY(-3px) scaleX(-1);
	}
}

@-webkit-keyframes bounce2 {
	0%, 50%, 100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	25% {
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px);
	}
	75% {
		-webkit-transform: translateY(-3px);
		transform: translateY(-3px);
	}
}

@keyframes bounce2 {
	0%, 50%, 100% {
		-moz-transform: translateY(0);
		-ms-transform: translateY(0);
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
	25% {
		-moz-transform: translateY(-5px);
		-ms-transform: translateY(-5px);
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px);
	}
	75% {
		-moz-transform: translateY(-3px);
		-ms-transform: translateY(-3px);
		-webkit-transform: translateY(-3px);
		transform: translateY(-3px);
	}
}

.fish1 {
	position: absolute;
	top: 37%;
	left: 14%;
	opacity: 40%;
	height: 32px;
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
	animation-name: bounce1;
	-webkit-animation: bounce1;
	animation-delay: 1s;
	-webkit-animation-delay: 2s;
	animation-duration: 12s;
	-webkit-animation-duration: 12s;
	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

.fish2 {
	position: absolute;
	top: 69%;
	left: 40%;
	opacity: 40%;
	height: 32px;
	animation-name: bounce2;
	-webkit-animation: bounce2;
	animation-duration: 12s;
	-webkit-animation-duration: 12s;
	animation-iteration-count: infinite;
	-webkit-animation-iteration-count: infinite;
}

@media only screen and (max-width: 1320px) {
	.pillar, .alga {
		display: none;
	}
}

@media only screen and (max-width: 800px) {
	.pillar, .alga {
		display: none;
	}

	.albumTitle {
		font-size: 2vh;
	}
}

@media only screen and (max-width: 600px) {
	.pillar, .alga {
		display: none;
	}

	.albumTitle {
		font-size: 1.8vh;
	}
}

@media only screen and (max-width: 450px) {
	.pillar, .alga {
		display: none;
	}

	.albumTitle {
		font-size: 18px;
	}

	.track2, .track3 {
		margin-left: 0;
	}

	.storyContainer p {
		max-width: 80%;
	}
}
