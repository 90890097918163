@font-face {
	font-family: 'Shippori Antique B1';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/shippori-antique-b1-v8-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('./fonts/shippori-antique-b1-v8-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('./fonts/shippori-antique-b1-v8-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('./fonts/shippori-antique-b1-v8-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('./fonts/shippori-antique-b1-v8-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('./fonts/shippori-antique-b1-v8-latin-regular.svg#ShipporiAntiqueB1') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Imbue';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/imbue-v20-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('./fonts/imbue-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('./fonts/imbue-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('./fonts/imbue-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('./fonts/imbue-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('./fonts/imbue-v20-latin-regular.svg#Imbue') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Special Elite';
	font-style: normal;
	font-weight: 400;
	src: url('./fonts/special-elite-v18-latin-regular.eot'); /* IE9 Compat Modes */
	src: local(''),
		 url('./fonts/special-elite-v18-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
		 url('./fonts/special-elite-v18-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
		 url('./fonts/special-elite-v18-latin-regular.woff') format('woff'), /* Modern Browsers */
		 url('./fonts/special-elite-v18-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
		 url('./fonts/special-elite-v18-latin-regular.svg#SpecialElite') format('svg'); /* Legacy iOS */
  }


* {
	margin: 0;
	padding: 0;
	box-sizing:border-box;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow-x: hidden;
}

html, body, #root {
	min-height: 100%;
	max-width: 100%;
}


code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}
