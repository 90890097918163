.modalContainer {
	position: absolute;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.5);
	height: 100vh;
	max-height: 100vh;
	width: 100%;
	max-width: 100%;
	z-index: 9999;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
}

.modalCover {
	max-height: 90%;
	max-width: 90%;
	cursor: auto;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
}

.clickableCover {
	display: block;
	cursor: pointer;
	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;
}
